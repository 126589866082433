@keyframes bounce {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1);
    }
}

.mainContainer {
    @apply absolute flex items-center justify-center top-4 right-0;
    border-radius: 50px 0 0 50px;
    padding: 4px 8px 4px 12px;
    border: 1px solid #ddd7dc;
    gap: 8px;
}

.bounce {
    animation: bounce 1.2s ease-in-out infinite;
}