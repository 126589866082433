.dropdownContainer {
  position: relative;
}

.dropdownButton {
  border: 1.5px solid #353533;
  color: black !important;
}

.dropdownButton svg {
  display: block;
  margin-left: 8px;
  transform: rotate(0deg);
  color: black !important;
}

.dropdownButton[data-state='open'] svg {
  transform: rotate(-180deg);
}

.dropdownItem {
  border: 1px solid black;
  border-top: none;
}

div[data-side='top'] .dropdownItem:first-child {
  border-top: 1px solid black;
}

div[data-side='top'] .dropdownItem:last-child {
  border-bottom: none;
}

.dropdownItem a {
  text-decoration: none;
  color: black;
}

.dropdownItem.dropdownItemLink {
  padding: 0;
}

.dropdownItem.dropdownItemLink a {
  padding: 0 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.dropdownButton,
.dropdownItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  padding: 0 8px;
  height: 32px;
  cursor: pointer;
  flex-shrink: 0;
  background-color: white;
  position: relative;
}

.dropdownButton.fullWidth {
  width: 100%;
}

.dropdownItems {
  position: absolute;
  width: 100%;
  z-index: 20;
}

.dropdownItem:hover {
  color: rgba(0, 0, 0, 0.5);
}
