.container {
  @apply flex flex-col items-center;
}

.list {
  @apply grid grid-cols-2 gap-3 gap-y-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full;
}

.count {
  @apply my-4 flex justify-center;
}

.button {
  @apply h-12 px-8 py-4 border border-black;
}
