
.container {
  border: 1px solid black;
  position: relative;
  max-width: none;
  font-size: 0.9rem;
}
@media (--tablet) {
  min-width: 320px;
  margin: 40px;
}

.form {
  display: flex;
}

.search {
  @apply bg-brandYellow;
  border-left: 1px solid black;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  padding: 12px;
  flex-grow: 1;
  outline-offset: 1px;
  margin-right: 1px;
}

.input:placeholder-shown + .clear {
  display: none;
}

.clear {
  @apply text-gray-400;
  transition: 0.2s ease;
  position: absolute;
  right: 50px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clear:hover, .clear:focus {
  @apply text-gray-900;
}

.suggestionWrapper {
  position: absolute;
  top: calc(100% + 10px);
  background-color: white;
  box-shadow: 0 0 0 1px rgba(35,38,59,0.1), 0 6px 16px -4px rgba(35,38,59,0.15);;
  width: 100%;
  z-index: 12;
  pointer-events: none;
  opacity: 0;
  transition: opacity .1s ease;
  display: flex;
  flex-direction: column;
  padding: 2px;
}

.inputWrapper:focus-within .suggestionWrapper:not(:empty) {
  opacity: 1;
  pointer-events: all;
  transition: opacity .2s ease-in;
}

.suggestion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px;
  padding: 8px;
}

.suggestion:hover {
  @apply bg-gray-100;
}