.container {
  @apply relative flex items-center justify-between;
}

.content {
  @apply flex items-center;
}

.image {
  @apply aspect-square w-10 mr-2 object-cover;
}

.link {
  @apply absolute h-full w-full top-0 left-0 z-10;
}

.dropdownButton {
  @apply mr-2 relative z-20;
}

.dropdownContent {
  @apply pl-12 mb-4;
}

.dropdownOpen .dropdownButton {
  @apply rotate-180;
}
