.container {
  @apply relative;
}

.title {
  @apply mb-4;
}

.items {
  @apply grid gap-2 mb-5;
}

.items-1 {
  @apply grid-cols-1;
}
.items-2 {
  @apply grid-cols-2;
}
.items-3 {
  @apply grid-cols-3;
}
.items-4 {
  @apply grid-cols-4;
}
.items-5 {
  @apply grid-cols-5;
}
.items-6 {
  @apply grid-cols-6;
}
.items-7 {
  @apply grid-cols-7;
}
.items-8 {
  @apply grid-cols-8;
}
.items-9 {
  @apply grid-cols-9;
}
