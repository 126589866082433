.container {
  @apply relative;
}

.title {
  @apply mb-6;
}

.items {
  @apply grid grid-cols-2 gap-5 mb-10;
}
