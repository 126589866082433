.container {
  @apply relative mt-3 pt-6 pb-8 flex flex-col;
}

.title {
  @apply mb-3;
}

.inputContainer {
  @apply border border-black h-12 w-full flex mt-3 max-w-sm mx-auto;
}

.inputCenter {
  @apply w-full flex flex-col items-center flex-1;
}

.input {
  @apply h-full flex-1 rounded-none placeholder-black75 px-4;
  border: none;
}

.submit {
  @apply bg-white h-full px-6 border-l border-black;
}

.loading .inputContainer {
  @apply opacity-50 pointer-events-none;
}

.response {
  @apply mt-2 text-center flex justify-between px-5 py-3 w-full max-w-sm mx-auto text-black50;
}

.success {
  @apply bg-brandGreen25;
}

.error {
  @apply bg-brandRed25;
}

@media (--tablet) {
  .container {
    @apply mt-0;
  }
}
