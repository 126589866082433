.container {
  @apply relative flex justify-between items-center py-3;
}

.logo {
  @apply w-52 max-h-16 object-contain;
}

.items {
  @apply hidden;
}

.link {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  white-space: nowrap;
  color: #57595b;
  font-weight: 500;
  letter-spacing: 0;
  font-size: 15px;
  margin: 0 30px;
  position: relative;
}

.link:hover::after {
  content: '';
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 2px solid #252d3a;
}

.cartLink {
  position: relative;
  display: block;
}

.cartLink svg {
  width: auto;
  height: 22px;
}

.cartLinkBubble {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -3px;
  right: 2px;
  color: #e66128;
  position: absolute;
  font-size: 10px;
}

.menuButton {
  @apply block;
}

.mobileLinks {
  @apply border-t mt-16;
}

.mobileLink {
  @apply border-b p-4 text-base uppercase;
}

@media (--tablet) {
  .menuButton {
    @apply hidden;
  }

  .container {
    @apply px-8 py-5;
  }

  .items {
    @apply inline-block;
  }
}
