.container {
  @apply relative flex flex-col items-center justify-center pt-6 px-10 pb-5 bg-brandYellow25;
}

.title {
  @apply mb-4;
}

.items {
  @apply grid grid-cols-2 gap-2;
}

@media (--tablet) {
  .items {
    @apply grid-cols-4;
  }
}
