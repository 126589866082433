.Footer {
  @apply bg-brandYellow w-full font-body pb-20;
}

.FooterList {
  @apply flex flex-col gap-4;
}

.FooterContainer {
  @apply flex justify-between flex-col md:flex-row;
}

.Upper {
  @apply mb-4;
}
.link {
  @apply text-sm;
}

.Shoppinglist {
  @apply mt-2;
}

.LogoContainer {
  @apply mt-7 mb-3;
}

.ShopListLink {
  @apply font-bold underline underline-offset-2 hover:no-underline;
}

.infoListContainer {
  @apply py-8 md:gap-9;
}

.infoList {
  @apply flex flex-col;
}

.infoListLink {
  @apply font-normal leading-8 underline;
}

.Lower {
  @apply border-t border-footerDivider;
}

.PrivacyTerms {
  @apply flex justify-center items-center w-full relative;
}

.PrivacyTerms .link {
  @apply text-xs;
}

.PrivacyTermsLink {
  @apply m-5;
}

.registeredName {
  @apply m-5 md:m-0;
}

.LinkListsContainer {
  @apply grid grid-cols-2;
}


@media (--tablet) {
  .Upper {
    @apply my-7;
  }

  .Shoppinglist {
    @apply mt-6;
  }

  .ShopListLink {
    @apply text-base;
  }

  .infoList {
    @apply text-right text-base;
  }

  .registeredName {
    @apply absolute left-0 top-1/2 -translate-y-1/2;
  }

  .LinkListsContainer {
    @apply flex flex-row flex-wrap justify-between gap-1 items-start w-3/4;
  }
}
