.list {
  @apply flex flex-col p-3 space-y-3;
}

.resellContainer {
  @apply bg-white px-4 py-5 col-span-2;
}

.resellContent {
  @apply mt-2 mb-4 max-w-xl text-sm text-gray-500;
}

input.resellInput {
  @apply shadow-sm block w-full border border-gray-700 py-3 px-3 text-black75 placeholder:text-black75 h-12;
}

.resellResponse {
  @apply mt-2 text-center flex justify-between px-5 py-3;
}

.resellSearchButton {
  @apply border border-gray-700 bg-brandYellow w-12 flex-shrink-0 flex justify-center items-center;
}

.resellCard {
  @apply bg-brandGrey50 p-3;
}

@media (--tablet) {
  .resellCard {
    @apply py-8;
  }
}
