.container {
  @apply relative flex flex-col items-center flex-shrink-0 w-full;
  width: calc(33.3% - 8px);
  scroll-snap-align: start;
}

.link {
  @apply absolute h-full w-full top-0 left-0 z-10;
}

.image {
  @apply aspect-square object-cover w-full;
}

.name {
  @apply my-3;
}

@media (--tablet) {
  .container {
    width: 100%;
  }
}
