
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(-360deg);
    }
}

.toggleSpinner {
    position: absolute;
    height: 65%;
    width: 65%;
    border-radius: 50%;
    border-left: 2px solid #535353;
    border-top: 2px solid #d0cece;
    border-right: 2px solid #d0cece;
    border-bottom: 2px solid #d0cece;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}