.container {
  @apply bg-brandGreen25 flex items-center p-3 gap-2 mb-4;
}

.arrow {
  @apply flex items-center;
}

@media (--tablet) {
  .container {
    @apply justify-center;
  }
}
