.container {
  @apply h-auto flex flex-col mt-6 w-full;
}

.products {
  @apply flex flex-1 gap-1 md:gap-4 overflow-x-scroll;
  scroll-snap-type: x mandatory;
}

.container * ::-webkit-scrollbar {
  display: none;
}

.products::-webkit-scrollbar {
  opacity: 0;
}

.slider {
  @apply bg-brandGrey50 w-full relative z-10;
}

.sliderHandle {
  @apply h-full bg-brandYellow;
  width: 86px;
  height: 6px;
}

.card {
  transform: scale(0.8) translateY(40px);
  transform-origin: top left;
}

@media (--tablet) {
  .products {
    @apply justify-start;
  }

  .slider {
    @apply hidden;
  }

  .title {
    @apply text-2xl;
  }
}

@media (max-width: 1200px) {
  .slider {
    @apply block;
  }
}

@media (--desktop) {
  .products {
    @apply grid grid-cols-5 justify-center;
  }

  .card {
    transform: scale(0.9) translateY(40px);
    transform-origin: top left;
  }
}