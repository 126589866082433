.container {
  @apply px-3 py-2 bg-white flex items-center justify-center border border-black text-black mx-auto mt-4;
  width: 40px;
}

.icon {
  @apply mr-2;
  margin: auto;
  width: 20px;
  height: 20px;
}

.text {
  text-decoration-thickness: 2px;
}

.container .text {
  @apply underline underline-offset-2;
}

@media (--tablet) {
  .container {
    @apply max-w-xs;
  }

  .container .text {
    text-decoration: none;
  }

  .container:hover .text {
    @apply underline underline-offset-2;
    text-decoration-thickness: 2px;
  }
}
