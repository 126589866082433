.container {
  @apply relative flex flex-col-reverse items-center justify-center bg-black;
}

.content {
  @apply flex flex-col justify-center items-start flex-shrink-0 w-full h-full p-5 pt-0;
}

.content h2 {
  @apply text-2xl font-display uppercase mb-4 border-t-2 pt-5;
}

.content h3 {
  @apply text-sm uppercase mb-4;
}

.imageContainer {
  @apply w-full h-full flex-shrink-0 relative p-5;
}

.image {
  @apply h-full w-full object-cover object-center;
}

.text {
  @apply text-white;
}

.link {
  @apply mt-5;
}

@media (--tablet) {
  .container {
    @apply flex-row;
  }

  .content {
    @apply w-1/2 pt-5;
  }

  .imageContainer {
    @apply w-1/2 p-0;
  }

  /* .logo {
    width: 600px;
  } */

  /* .text {
    @apply text-base;
  } */
}
