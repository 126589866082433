.content h2 {
  @apply font-display text-xl font-extrabold mb-4;
}

.content {
  @apply font-body  text-center;
}

.content p {
  @apply mb-8 text-base;
}

.error {
  @apply text-digitalRed mt-3;
}

@media (--tablet) {
  .content h2 {
    @apply text-2xl;
  }

  .content p {
    @apply text-lg;
  }
}