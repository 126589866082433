.HeaderLinkIcon {
  @apply h-full flex items-center justify-center px-2;
}

.HeaderLink {
  @apply text-black flex items-center justify-center top-2 pb-4 cursor-pointer h-11 relative;
}

@media (--tablet) {
  .HeaderLinkIcon {
    @apply pl-0;
  }

  .HeaderLink span {
    @apply text-base;
  }
}