.button {
  @apply px-5 py-3 flex items-center justify-center text-black uppercase h-12 transition-colors border border-brandYellow;
  position: relative;
  transition: 0.15s ease-out;
}

.button:not([disabled]) {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);
  transform: translateY(0px);
}

.button:hover:not([disabled]) {
  transform: translateY(1px);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.button:focus:not([disabled]) {
  transform: translateY(1px);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.brandButton {
  @apply bg-brandYellow border-b;
}

.brandButton[disabled] {
  @apply bg-brandGrey50 text-black50 border-brandGrey50;
}

.secondaryButton {
  @apply bg-white border border-gray-200;
}

.loading {
  background-color: black;
  color: white;
}

.fullWidth {
  @apply w-full;
}

.icon {
  @apply ml-2;
}

.icon.iconLeft {
  @apply ml-0 mr-2;
}