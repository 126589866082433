.container {
  @apply relative h-56;
}

.image {
  @apply absolute top-0 left-0 h-full w-full object-cover;
}

.textContainer {
  @apply absolute bg-white bottom-3 left-3 px-4 py-3 text-left border-b-2 border-brandYellow;
}

.link {
  @apply absolute h-full w-full top-0 left-0 z-10;
}

.arrow svg {
  @apply inline ml-1;
}

@media (--tablet) {
  .container {
    @apply h-72;
  }
}
