.container {
  @apply h-96 flex flex-col items-center justify-center;
}

.title {
  @apply mb-2;
}

.subtitle {
  @apply mb-4 text-center;
}
