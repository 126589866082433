.container {
  @apply relative flex items-center;
}

.container:hover .text {
  @apply border-transparent;
}

.text {
  @apply border-b-2 border-brandYellow transition-colors whitespace-nowrap;
}

.icon {
  @apply ml-2;
}

.icon.iconLeft {
  @apply ml-0 mr-2;
}
