.container {
  @apply relative px-3 py-2 inline-flex items-center justify-center border-black bg-brandYellow text-black hover:underline underline-offset-4;
  text-decoration-thickness: 2px;
}

.container.secondary {
  @apply bg-white border;
}

.icon {
  @apply ml-2;
}

.icon.iconLeft {
  @apply ml-0 mr-2;
}
