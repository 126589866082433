.container {
  @apply relative max-h-12 w-full h-full my-2;
}

.image {
  @apply h-full w-full object-contain;
}

.link {
  @apply absolute h-full w-full top-0 left-0 z-10;
}
