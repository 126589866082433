.container {
  @apply relative border border-brandGrey aspect-square flex justify-center items-center p-3;
}

.image {
  @apply w-4/5;
  max-width: 90%;
}

.link {
  @apply absolute h-full w-full top-0 left-0 z-10;
}
