.container {
  @apply relative overflow-hidden border border-gray-300;
}

.imageContainer {
  @apply w-full  overflow-hidden relative;
  aspect-ratio: 3/4;
}

.image {
  @apply h-full object-contain object-center lg:h-full lg:w-full;
}

.actions {
  @apply absolute flex justify-end items-end bottom-2 w-full px-2;
}

button.addToCart {
  @apply absolute h-10 mr-2 opacity-0 invisible left-1/2 -translate-x-1/2 translate-y-full w-max;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.wishlist {
  top: initial;
  left: initial;
  width: 24px;
  height: 24px;
}

.title {
  @apply mt-2 xl:mt-3 truncate;
}

.description {
  @apply truncate text-black50;
}

.badgeWrapper {
  @apply absolute top-0 flex flex-wrap justify-between w-full gap-1;
}

.card {
  content-visibility: auto;
  /*@apply grid grid-cols-2 gap-3 gap-y-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full;*/

  contain-intrinsic-size: auto calc((50vw - 1.125rem) * 1.33 + 76px);
  @media (min-width: 768px) {
    contain-intrinsic-size: auto calc((33.33vw - 1rem)  * 1.33 + 76px);
  }
  @media (min-width: 1024px) {
    contain-intrinsic-size: auto calc((25vw - 0.9375rem) * 1.33 + 76px);
  }
  @media (min-width: 1300px) {
    contain-intrinsic-size: auto 401px;
  }
}

.badge {
  @apply py-1 px-2 text-xs font-bold uppercase;
}

@media (--tablet) {
  button.addToCart {
    @apply visible;
  }
}

/* HOVER */
.container:hover button.addToCart {
  @apply opacity-100 translate-y-0;
}
