.container {
  @apply flex items-center mt-3;
}

.item {
  @apply flex overflow-hidden max-w-2/5 text-xs;
}

.itemLink {
  @apply capitalize overflow-hidden whitespace-nowrap text-ellipsis;
}

.spacer {
  @apply inline-block mx-2;
}

@media (--tablet) {
  .item {
    @apply max-w-none;
  }
}
