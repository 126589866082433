.container {
  @apply py-4 border-b last:border-none;
}

.container h1,
.container h2,
.container h3,
.container h4,
.container h5,
.container h6 {
  @apply font-display text-lg font-bold leading-6 bg-brandYellow50 inline uppercase;
}

.container i {
  font-style: normal;
  font-size: 24px;
}
