.container {
  @apply relative h-full opacity-0 transition-opacity md:my-8;
  display: flex;
  flex-direction: column;
}

.container.loaded {
  @apply opacity-100;
}

.slider {
  width: 100%;
}

@media (--tablet) {
  .container {
    @apply px-8;
  }
}

/* Carousel */

.carouselControls {
  @apply absolute bottom-5 right-3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carouselDotGroup {
  position: relative;
  width: 130px;
  height: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.carouselDotWrapper {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  transition: transform ease-in-out 0.4s;
}

.carouselDotWrapper.staticDots {
  position: relative;
}

.carouselDot {
  @apply bg-brandYellow mx-1;
  border: 1px solid black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0;
  cursor: pointer;
}

.carouselDot:hover {
  @apply bg-brandGrey;
}

.carouselDot:focus {
  @apply bg-brandBlue;
}

.carouselDot.active {
  background: black;
}

.carouselDot.indicator {
  border: 2px solid grey;
}

.slidePrev,
.slideNext {
  @apply bg-white rounded-full;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.slidePrev:disabled,
.slideNext:disabled {
  display: none;
}

.slidePrev svg,
.slideNext svg {
  @apply h-full w-full block;
  flex-shrink: 0;
}

.slidePrev {
  @apply left-3;
}

.slideNext {
  @apply right-3;
}

@media (--tablet) {
  .slidePrev,
  .slideNext {
    @apply h-6 w-6;
  }

  .slidePrev {
    @apply left-0;
  }

  .slideNext {
    @apply right-0;
  }

  .carouselControls {
    bottom: 24px;
    right: 50%;
    transform: translateX(50%);
  }

  .carouselControls.carouselControlsOffset {
    bottom: -24px;
  }

  .carouselDotWrapper {
    @apply justify-center;
  }
}
