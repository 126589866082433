.title {
  @apply text-lg my-6;
}

.gridBlock {
  @apply grid grid-cols-3 gap-5;
}

.Brand {
  @apply flex flex-col items-center p-3;
}
