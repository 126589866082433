.ImageContainer {
  @apply w-full md:w-5/12 overflow-visible mt-0  px-3;
}

.MainContainer {
  @apply w-full md:w-7/12 mt-3 px-3;
}

.DetailsContainer {
  @apply w-full  mt-3 p-3 max-w-full;
}

.product {
  @apply block md:flex mt-0;
}

.title {
  @apply mt-3 capitalize truncate;
}

.description {
  @apply truncate text-black50 mb-1;
}

.condition {
  @apply mr-1;
}

.buttonContainer {
  @apply flex flex-row justify-between md:justify-start items-center flex-wrap my-3 gap-3 w-full;
}

.largeTitle {
  @apply uppercase;
}

.smallTitle {
  @apply uppercase mb-2;
}

.block {
  @apply my-3;
}

.relatedProducts {
  @apply bg-brandYellow25;
}
