/*  */
.DrawerDialog {
  @apply relative z-10;
}

.Drawer {
  @apply pointer-events-none fixed inset-y-0 flex max-w-full;
}

.DrawerBg {
  @apply fixed inset-0 bg-gray-500 bg-opacity-75 transition z-10;
  z-index: 2147483647;
}

.DrawerOuter {
  @apply fixed inset-0 overflow-hidden;
}

.DrawerInner {
  @apply absolute inset-0 overflow-hidden;
}

.DrawerPanel {
  @apply pointer-events-auto relative max-w-md;
  width: calc(100vw - 2.5rem);
}

.DrawerPanelWide {
  @apply max-w-4xl;
}

.CloseButton {
  @apply absolute top-4 -right-8 flex outline-none;
}

.CloseButton.CloseButtonDrawerRight {
  @apply -left-8 right-auto;
}

.CloseButtonIcon {
  @apply rounded-md text-black  outline-none;
}

.DrawerContainer {
  @apply flex h-full flex-col overflow-y-scroll bg-white  shadow-xl;
}

.DrawerContainerPadding {
  @apply px-4 sm:px-6;
}

.DrawerTitle {
  @apply text-lg font-medium text-gray-900;
}

.DrawerContent {
  @apply relative flex-1 px-4 sm:px-6;
}

.DrawerContentInner {
  @apply absolute inset-0 px-2;
}
