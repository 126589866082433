.container {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: gray;
  opacity: 0.3;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerContainer {
  position: fixed;
  width: calc(100% - 70px);
  background-color: white !important;
  padding: 40px;
  text-align: center;
  z-index: 1001;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.title {
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
}

.subtitle {
  padding-bottom: 16px;
  font-size: 20px;
  font-weight: 700;
  font-family: 'inter';
}

.description {
  padding-bottom: 25px;
  line-height: 20px;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 40px;
}

.buttonContainer > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.acceptBtn {
  width: 100%;
  background-color: #fff501;
  border-bottom: 1.5px solid black;
  font-weight: 700;
}

.declineBtn {
  width: 100%;
  opacity: 0.6;
}
