.container {
  @apply relative mb-6 last:mb-0;
}

.content {
  @apply flex items-center;
}

.image {
  @apply aspect-square w-10 mr-2;
}

.link {
  @apply absolute h-full w-full top-0 left-0 z-10;
}
