.list {
  @apply flex transition-opacity justify-between;
}

.listLoading {
  @apply opacity-50 pointer-events-none;
}

.imageCont {
  @apply w-24 flex-shrink-0 relative;
}

.image {
  @apply border border-gray-200;
}

.itemCont {
  @apply ml-3 w-3/5 space-y-1 justify-center flex flex-col flex-grow;
}

.btnCont {
  @apply justify-end flex flex-row;
}

.soldOut {
  @apply absolute top-0 left-0 h-full w-full flex justify-center items-center bg-white25;
}

.btnCol {
  @apply flex flex-col justify-end;
}