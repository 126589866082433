.container {
  @apply relative flex flex-col items-start;
}

.title {
  @apply mb-4;
}

.items {
  @apply grid grid-cols-3 gap-3 mb-7;
}

.image {
  @apply aspect-square;
}

.instaLink {
  @apply mb-4 font-bold text-sm text-right;
}

@media (--tablet) {
  .items {
    @apply grid-cols-9 gap-5;
  }
}
