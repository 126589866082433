.heading {
  @apply text-center my-6;
}

.container h1,
.container h2,
.container h3,
.container h4,
.container h5,
.container h6 {
  @apply font-display text-lg font-bold leading-6 uppercase;
}

.container img {
  @apply block my-4;
}

.container p:first-child img {
  @apply mt-0;
}

.narrow {
  @apply mx-auto px-3 text-center;
  max-width: 60rem;
}
