.tooltip {
  @apply absolute bg-black text-white top-full border border-black p-1 shadow-md opacity-0 pointer-events-none normal-case;
  transition: 0.2s ease;
  top: calc(100% + 6px);
  white-space: pre-line;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  width: 80%;
  min-width: 200px;
}

.tooltip.open {
  opacity: 1;
}

.tooltipArrow {
  @apply absolute top-0 left-1/2;
  transform-origin: center center;
  transform: translate(-50%, -50%) rotateZ(-45deg);
  border-top: 4px solid black;
  border-right: 4px solid black;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
}