.container {
  @apply mx-auto max-w-6xl;
}

.grid1 {
  @apply mt-2 grid gap-y-10 gap-x-6 grid-cols-2 lg:grid-cols-4 xl:gap-x-4;
}

.grid2 {
  @apply mt-2 grid gap-y-10 gap-x-2 grid-cols-2 lg:grid-cols-4 xl:gap-x-4;
}

.grid3 {
  @apply mt-2 grid gap-y-6 gap-x-2 grid-cols-3 md:grid-cols-6 xl:gap-x-4;
}

.item {
  @apply relative;
}
