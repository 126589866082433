.loader {
    width: 200px;
    height: 200px;
}

.loader-inner {
    min-width: 60px;
    position: relative;
    aspect-ratio: 1 / 1;
    animation: 1.25s infinite bounceout;
}

.loader-inner div {
    background: black;
}

.loader-t-vert {
    width: 28%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 36%;
}

.loader-t-horz {
    height: 28%;
    width: 100%;
    position: absolute;
    top: 36%;
}

.loader-dot {
    height: 25.5%;
    width: 26.6%;
    position: absolute;
    bottom: 74.5%;
    left: 36.7%;
}

@keyframes bounceout {
    from {
        transform: scale(1.1, 1.1);
        animation-timing-function: cubic-bezier(0.33, 0, 0.66, 0.33);
    }

    50% {
        transform: scale(0.9, 0.9);
        animation-timing-function: cubic-bezier(0.33, 0.66, 0.66, 1);
    }

    to {
        transform: scale(1.1, 1.1);
        animation-timing-function: cubic-bezier(0.33, 0, 0.66, 0.33);
    }
}