.container {
  @apply relative;
}

.title {
  @apply mb-4;
}

.items {
  @apply mb-6;
}

.link {
  @apply absolute h-full w-full top-0 left-0;
}

@media (--tablet) {
  .gridLarge .items {
    @apply grid grid-cols-2 gap-10;
  }
}
