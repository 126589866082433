.container {
  @apply relative;
}

.title {
  @apply mb-4;
}

.items {
  @apply mb-2 gap-4 md:px-12 md:pt-2;
}

.signpost {
  @apply flex justify-center items-center;
}

.link {
  @apply absolute h-full w-full top-0 left-0;
}

@media (--tablet) {
  .items {
    @apply flex justify-between w-full px-12;
  }

  .item {
    @apply w-full flex-col gap-4 items-center;
  }

  .title {
    @apply font-display text-xl font-extrabold uppercase pb-5;
    font-size: 30px;
  }
}

@media (max-width: 1068px) {
  .items {
    @apply flex-wrap;
  }
}