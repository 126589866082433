.hero {
  @apply bg-brandGrey25 p-8  flex flex-col items-center justify-center text-center  col-span-2 relative;
}

.hero.hasImage {
  @apply pb-0;
}

.closeButton {
  @apply absolute top-2 right-2 z-10;
}

.content {
  @apply relative z-10 w-full;
}

.signpost {
  @apply flex flex-col items-center;
}

section.modalCarousel {
  @apply p-0 bg-white;
}

.image {
  padding: 2rem 5rem;
  max-width: 400px;
}

@media (--tablet) {
  .title {
    @apply text-2xl;
  }

  .enlargeTitle .title {
    font-size: 50px;
    line-height: 60px;
  }

  .hero.hasBgImage {
    @apply h-72 pb-8;
  }

  .hero.hasBgImage .image {
    @apply absolute h-full w-full top-0 left-0 object-cover p-0;
    max-width: none;
  }
}

@media (--desktop) {
  .hero {
    @apply mx-3;
  }
}

/** Text alignment styles **/

/* Right */
.textRight {
  @apply items-end;
}

.textRight .signpost {
  @apply items-end;
}

@media (--tablet) {
  .textRight {
    @apply items-end;
  }

  .textRight .content {
    @apply text-right;
  }
}

/* Centre */
.textCentre {
  @apply items-center;
}

.textCentre .signpost {
  @apply items-center;
}

@media (--tablet) {
  .textCentre {
    @apply items-center;
  }

  .textCentre .content {
    @apply text-center;
  }
}

/* Left */

@media (--tablet) {

  .textLeft .signpost {
    @apply items-start;
  }

  .textLeft {
    @apply items-start;
  }

  .textLeft .content {
    @apply text-left;
  }

  .textLeft .title {
    @apply items-start;
  }
}