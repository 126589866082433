.items {
  @apply grid gap-2;
}

.items-1 {
  @apply grid-cols-1;
}
.items-2 {
  @apply grid-cols-2;
}
.items-3 {
  @apply grid-cols-3;
}
.items-4 {
  @apply grid-cols-4;
}
.items-5 {
  @apply grid-cols-5;
}
.items-6 {
  @apply grid-cols-6;
}
.items-7 {
  @apply grid-cols-7;
}
.items-8 {
  @apply grid-cols-8;
}

@media (--tablet) {
  .items {
    @apply grid-cols-2;
  }

  .items-large-1 {
    @apply grid-cols-1;
  }
  .items-large-2 {
    @apply grid-cols-2;
  }
  .items-large-3 {
    @apply grid-cols-3;
  }
  .items-large-4 {
    @apply grid-cols-4;
  }
  .items-large-5 {
    @apply grid-cols-5;
  }
  .items-large-6 {
    @apply grid-cols-6;
  }
  .items-large-7 {
    @apply grid-cols-7;
  }
  .items-large-8 {
    @apply grid-cols-8;
  }
}
