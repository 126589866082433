.Categories {
  @apply flex justify-start py-2 gap-6;
}

.CategoriesHeader {
  @apply hidden md:inline-block w-full bg-brandGrey;
  border-bottom: 2px solid black;
}

.Category {
  @apply hover:underline;
}

.ActiveTab {
  @apply font-bold;
}

.Sticky {
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  z-index: 10000;
}

.Logo {
  @apply flex items-center justify-center;
}