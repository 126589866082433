.container {
  @apply -mx-3;
}

.container :global(.rich-text) ul {
  @apply list-disc pl-10 my-2;
}

.container :global(.rich-text) li {
  @apply mb-2;
}

.container :global(.rich-text) li p {
  @apply mt-0;
}

.container :global(.rich-text) p:not(:first-child) {
  @apply mt-6;
}
