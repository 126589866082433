.container {
  @apply flex items-center flex-col text-center flex-1;
}

.icon {
  @apply w-20 object-contain;
}

@media (--tablet) {
  .container {
    @apply flex-row text-left flex-initial;
  }
}
