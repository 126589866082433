.container,
.sliderContainerStoryblok {
  @apply relative;
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
  height: 100%;
  text-align: center;
}

.sliderContainer {
  @apply relative flex flex-col justify-center items-center h-full text-center w-full mx-auto border border-brandGrey;
  height: 0;
  /* padding-top: 75%; */
  /*padding-top: 112%;*/
  padding-top: 133%;
  position: relative;
}

.sliderContainer :global(.keen-slider) {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
}

.slider {
  width: 100%;
}

@media (--tablet) {
  .sliderContainer {
    @apply w-full;
  }
}

/* Carousel */

.carouselControls {
  @apply absolute bottom-5 right-3 flex flex-col items-center;
}

.carouselControlsCenter {
  @apply absolute bottom-5 flex flex-col items-center;
}

.carouselDotGroup {
  position: relative;
  width: 130px;
  height: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.carouselDotWrapper {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  transition: transform ease-in-out 0.4s;
}

.carouselDotWrapper.staticDots {
  position: relative;
  /* justify-content: center; */
}

.carouselDot {
  @apply bg-brandYellow mx-1;
  border: 1px solid black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0;
  cursor: pointer;
}

.carouselDot:hover {
  @apply bg-brandGrey;
}
.carouselDot:focus {
  @apply bg-brandBlue;
}

.active {
  background: black;
  @apply border border-brandYellow;
}

.carouselDot.indicator {
  border: 2px solid grey;
}

.slidePrev,
.slideNext {
  @apply flex justify-center rounded-full items-center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* background: none; */
  border: none;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.disabled {
  display: none !important;
}

.slidePrev svg,
.slideNext svg {
  @apply block;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
}

.slidePrev {
  @apply left-3;
}

.slideNext {
  @apply right-3;
}

.slide {
  @apply relative w-full h-full cursor-grab active:cursor-grabbing;
  /*cursor: url('/search_icon.png'), auto;*/
}

.slide .img {
  @apply h-full w-full object-cover absolute top-0 left-0 cursor-grab active:cursor-grabbing;
}

.slide:first-child .img {
  @apply object-contain;
}