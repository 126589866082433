.container {
  @apply h-auto flex flex-col md:flex-row md:gap-10 w-full mb-10 md:border-b-4 md:border-t-4 md:border-brandGrey;
}


.searches {
  @apply flex flex-col flex-1 gap-2 p-4;
  scroll-snap-type: x mandatory;
}

@media (--tablet) {
  .searches {
    @apply flex flex-row flex-wrap;
  }

  .title {
    @apply flex items-center text-2xl whitespace-nowrap;
  }
}

@media (--desktop) {
  .searches {
    @apply flex flex-row gap-6;
  }
}