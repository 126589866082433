.toggleContainer {
  @apply border-b mb-3;
}

.sharedContainer {
  @apply border-t;
}

.drawerWrapper {
  @apply flex h-full flex-col;
}

.drawerContent {
  @apply px-2 overflow-scroll flex-1 pt-2 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-3;
}

.drawerFooter {
  @apply py-4 px-4 border-t border-gray-200 flex flex-col justify-between;
}

.emptyCart {
  @apply flex flex-col justify-center items-center h-48;
}

.soldOut {
  @apply absolute top-0 left-0 h-full w-full flex justify-center items-center bg-white25;
}
