.container {
  @apply relative;
}

.title {
  @apply mb-4;
}

.itemsContainer {
  @apply relative;
}

.items {
  @apply flex flex-1 gap-4 overflow-x-scroll;
  scroll-snap-type: x mandatory;
}

.container * ::-webkit-scrollbar {
  display: none;
}

.items::-webkit-scrollbar {
  opacity: 0;
}



.slider {
  @apply bg-brandGrey50 w-full relative z-10;
}

.sliderHandle {
  @apply h-full bg-brandYellow;
  width: 86px;
  height: 6px;
}

.overlay {
  @apply absolute right-0 top-0 h-full w-1/5;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

@media (--tablet) {
  .title {
    @apply text-2xl;
  }

  .items {
    @apply grid grid-cols-6 justify-center;
  }

  .slider {
    @apply hidden;
  }

  .overlay {
    @apply hidden;
  }
}
