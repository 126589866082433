.items {
  @apply flex flex-col gap-4 p-3 mb-4;
}

.item {
  @apply relative capitalize;
}

.link {
  @apply absolute h-full w-full top-0 left-0 z-10;
}
