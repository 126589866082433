.container {
  background-color: #e5e6e1;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.image {
  display: 'block';
  /* margin-top: -10 */
  padding-top: 44px;

  padding-bottom: 44px;
}

.image > img {
  max-height: 40vh;
  max-width: 100%;
  object-fit: contain;
}

.subtitle {
  font-size: 18px;
  padding-bottom: 44px;
}

.buttonContainer {
  display: flex;
  gap: 8px;
  width: 100%;
  padding-bottom: 38px;
}

.buttonContainer > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50%;
  border-bottom: 2px solid black;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Faktum';
}

.btnLeft {
  background-color: #fff239;
}

.btnRight {
  background-color: white;
}

.bottomText {
  font-size: 17px;
}

@media (max-height: 700px) {
  .image > img {
    max-height: 35vh;
  }
}

@media (max-height: 650px) {
  .image > img {
    max-height: 30vh;
  }

  .image {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .subtitle {
    padding-bottom: 22px;
  }

  .buttonContainer {
    padding-bottom: 22px;
  }
}
