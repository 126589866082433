.title {
  @apply text-lg my-6;
}

.iconGridBlock {
  @apply grid grid-cols-3 gap-3;
}

.iconListGrid {
  @apply space-y-1 pt-1 pb-2 md:space-y-1 md:py-3;
}

.Brand {
  @apply flex flex-col  items-center p-3;
}

.iconBlock {
  @apply flex flex-col md:flex-row items-center  md:px-6 my-6 md:stroke-brandGrey50 md:border-r md:last:border-r-0;
}

.iconList {
  @apply flex flex-row items-center   md:stroke-brandGrey50;
}

.text {
  @apply mr-3 text-center md:text-left;
}
