.container {
  @apply relative flex items-center mb-5;
}

.image {
  @apply aspect-square flex-shrink-0 mr-4 w-28;
}

.textContainer {
  @apply flex flex-col justify-center;
}

.title {
  @apply mb-1;
}

.link {
  @apply absolute h-full w-full top-0 left-0;
}

/**  Image alignment styles  **/
/* Right */
.imageRight {
  @apply flex-row-reverse;
}

.imageRight .image {
  @apply mr-0 ml-4;
}


@media (--tablet) {
  .image {
    @apply w-36;
  }

  .title {
    @apply font-display text-xl font-extrabold uppercase;
  }

  .description {
    @apply text-base w-80;
  }

  .description p::after {
    content: '';
    display: block;
    margin-bottom: 1em;
  }

  .enlargeItem .image {
    @apply w-80;
  }

  .enlargeItem .title {
    @apply font-display text-xl font-extrabold uppercase;
    font-size: 25px;
  }

  .enlargeItem .description {
    @apply text-base w-80;
  }
}
