.container {
  @apply relative;
}

.title {
  @apply mb-4;
}

.items {
  @apply flex flex-col items-start;
}
