.container {
  @apply h-auto flex flex-col py-6 w-full;
}

.text {
  @apply border-b-2 border-brandYellow transition-colors whitespace-nowrap;
}

.container:hover .text {
  @apply border-transparent;
}

.container * ::-webkit-scrollbar {
  display: none;
}

.linkContainer {
  @apply relative px-4 py-3 inline-flex items-center justify-center border-black bg-brandYellow text-black hover:underline underline-offset-4;
  text-decoration-thickness: 2px;
}

.products {
  @apply flex gap-4 overflow-x-scroll mb-2;
  scroll-snap-type: x mandatory;
}

.product-item {
  @apply flex-shrink-0;
}

.products::-webkit-scrollbar {
  opacity: 0;
}

.slider {
  @apply bg-brandGrey50 w-full relative z-10;
}

.sliderHandle {
  @apply h-full bg-brandYellow;
  width: 86px;
  height: 6px;
}

.card {
  transform: scale(0.8) translateY(40px);
  transform-origin: top left;
}

.title {
  @apply mb-4;
}

@media (--tablet) {
  .products {
    @apply justify-start;
  }

  .slider {
    @apply hidden;
  }

  .title {
    @apply text-2xl;
  }
}