.container {
  @apply relative flex flex-col items-center my-5 w-1/3;
}

.wrapper {
  @apply flex items-center justify-center;
}

.text {
  @apply ml-1;
  white-space: nowrap;
}

.description {
  @apply hidden;
}

@media (--tablet) {
  .container {
    @apply mx-0 mt-7 mb-8;
  }

  .container:not(:last-child)::after {
    @apply absolute right-0 h-8 bg-brandGrey;
    content: '';
    width: 1px;
    top: 50%;
    transform: translateY(-50%);
  }

  .wrapper {
    @apply flex-row mb-1;
  }

  .text {
    @apply text-base ml-2;
  }

  .description {
    @apply block;
  }
}
