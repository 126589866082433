.toggleContainer {
  @apply border-b mb-3;
}

.sharedContainer {
  @apply border-t;
}

.drawerWrapper {
  @apply flex h-full flex-col;
}

.drawerContent {
  overflow: scroll;
  flex: 1;
}

.drawerFooter {
  @apply py-4 px-4 border-t border-gray-200 flex flex-col justify-between;
}

.emptyCart {
  @apply flex flex-col justify-center items-center h-48;
}
