@tailwind base;
@tailwind components;
@tailwind utilities;

/*======= * General Styles * ========*/

a:hover {
  text-decoration: none;
}

body {
  @apply font-body;
  background-color: #fff;
}

/*======= * Base Styles * ========*/

@layer components {
  /* Body styles */
  /* 10 */
  .body-10-bold {
    @apply font-body text-2xs font-bold;
  }
  .body-10-bold-fixed {
    @apply font-body text-2xs font-bold;
  }

  /* 12 */
  .body-12 {
    @apply font-body text-xs;
  }

  .body-12-bold {
    @apply text-xs font-bold;
  }

  .body-12-bold-caps {
    @apply font-body text-xs font-bold uppercase;
  }

  .body-12-underline {
    @apply font-body text-xs underline;
  }

  /* 14 */
  .body-14 {
    @apply font-body text-sm;
  }

  .body-14-underline {
    @apply font-body text-sm  underline;
  }

  .body-14-bold {
    @apply font-body text-sm font-bold;
  }

  .body-14-bold-caps {
    @apply font-body text-sm font-bold uppercase;
  }

  .body-14-bold-underline {
    @apply font-body text-sm font-bold underline;
  }

  /* 16  */
  .body-16 {
    @apply font-body text-base;
  }

  .body-16-bold {
    @apply font-body text-base font-bold;
  }

  .body-16-underline {
    @apply font-body text-base underline;
  }

  .body-16-bold-underline {
    @apply font-body text-base font-bold underline;
  }

  .body-18 {
    @apply font-body text-lg;
    line-height: 26px;
  }

  /* 18 */
  .body-18-bold {
    @apply font-body text-lg font-bold;
    line-height: 26px;
  }

  /* 20 */
  .body-20-bold {
    @apply font-body text-xl font-bold;
  }

  /* Display text styles */
  /* 10 */
  .display-10-caps {
    @apply font-display text-2xs font-extrabold uppercase;
  }

  /* 14 */
  .display-14 {
    @apply font-display text-sm font-extrabold;
  }

  /* 18 */
  .display-18 {
    @apply font-display text-lg font-extrabold leading-6;
  }
  .display-18-caps {
    @apply font-display text-lg font-extrabold leading-6 uppercase;
  }

  /* 20 */
  .display-20 {
    @apply font-display text-xl font-extrabold;
  }
  .display-20-caps {
    @apply font-display text-xl font-extrabold uppercase;
  }

  /* 24 */
  .display-24 {
    @apply font-display text-2xl font-extrabold;
  }

  .display-24-caps {
    @apply font-display text-2xl font-extrabold uppercase;
  }

  /** Button text styles **/

  .button-12-caps {
    @apply font-button text-xs font-bold uppercase;
  }

  .button-14 {
    @apply font-button text-sm font-bold;
  }

  /*======= * Layout Styles * ========*/

  .Page {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-flow: dense;
  }

  .Page section {
    grid-column: span 2;
  }

  .Container {
    @apply mx-auto md:max-w-7xl text-black;
  }

  .Section {
    @apply mx-3;
  }

  .Section--small {
    @apply max-w-sm;
  }

  .PageContent {
    @apply md:max-w-2xl mx-auto my-12;
  }

  .Section--spaced {
    @apply mt-8;
  }

  .forced-aspect-square {
    width: 100%;
    position: relative;
    height: 0;
    padding-top: 100%;
  }

  .forced-aspect-square > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (--tablet) {
    .Page section.SingleColumnLarge {
      grid-column: span 1;
    }

    .Section--spaced {
      @apply mt-11;
    }

    .Section--tablet {
      @apply mx-3;
    }
  }

  /* Default Icon Styling */

  .circle-icon:hover circle {
    @apply fill-brandGrey;
  }
  .circle-icon:focus circle {
    @apply fill-brandBlue;
  }

  .circle-icon:hover line,
  .circle-icon:focus line {
    @apply stroke-black;
  }

  .circle-icon-border:hover circle {
    @apply stroke-black;
  }
}

/* ALGOLIA */

.header {
  background: rgb(252 252 255 / 92%);
  box-shadow: 0 0 0 1px rgba(35, 38, 59, 0.05), 0 1px 3px 0 rgba(35, 38, 59, 0.15);
  padding: 0.5rem 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.header-wrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 100px 1fr;
}

.header-nav {
  font-weight: 500;
}

.wrapper {
  margin: 0 auto;
  max-width: 80rem;
  padding: 0 1.5rem;
  width: 100%;
}

.container {
  margin-top: 3.5rem;
  padding: 1.5rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 3fr;
}

/* Autocomplete */

div.aa-Panel {
  /* position: fixed; */
  border-radius: 0;
  border: 1px solid transparent;
  z-index: 10;
}

section.aa-Source {
  display: none;
}

section.aa-Source:last-child {
  display: block;
}

/* InstantSearch */

.ais-Hits-list {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.ais-Hits-item {
  padding: 1rem !important;
}

.hit {
  align-items: center;
  display: grid;
  gap: 1rem;
}

.hit h1 {
  font-size: 1rem;
}

.hit p {
  font-size: 0.8rem;
  opacity: 0.8;
}

.hit-image {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
}

.hit-image img {
  max-height: 100%;
}

.ais-HierarchicalMenu-item--selected.ais-HierarchicalMenu-item--parent > div:first-of-type .ais-HierarchicalMenu-label {
  font-weight: bold;
}

.ais-HierarchicalMenu-item--selected:not(.ais-HierarchicalMenu-item--parent) .ais-HierarchicalMenu-label {
  font-weight: bold;
}

.ais-Pagination {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}

/* React Modal */
.ReactModalPortal {
  @apply relative z-10;
}

/* Inputs */

input[type='email'],
input[type='password'],
input[type='text'] {
  @apply border border-black p-2 font-body text-sm;
}

input[type='email'].inputSecondary,
input[type='password'].inputSecondary,
input[type='text'].inputSecondary {
  @apply border border-brandGrey p-2 font-body text-sm;
}

/* Richtext */
.rich-text ul {
  @apply mb-4;
}

.rich-text a {
  @apply underline;
}

.rich-text h1:not(:first-child),
.rich-text h2:not(:first-child),
.rich-text h3:not(:first-child) {
  @apply mt-3;
}

.rich-text p:not(:first-child) {
  @apply mt-2;
}

.rich-text img {
  @apply m-auto;
}

/* Notifications popup */

.popup-open {
  overflow: hidden;
}

/* Smile */

/* very annoyingly sets the z-index to the max by default, so lets fix that */
#smile-ui-container {
  z-index: 10 !important;
}