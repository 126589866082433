.container {
  @apply relative flex items-start;
}

.container:not(:last-child) {
  @apply mb-4;
}

.image {
  @apply w-1/3 aspect-square flex-shrink-0 mt-3  z-10 lg:w-1/4;
}

.textContainer {
  @apply bg-brandGreen25 px-8 py-6;
  margin-left: -12px;
}

.quote {
  @apply mb-2;
}

.link {
  @apply absolute h-full w-full top-0 left-0;
}

/**  Image alignment styles  **/
/* Right */

.imageRight {
  @apply flex-row-reverse;
}

.imageRight .textContainer {
  margin-left: 0;
  margin-right: -12px;
}

@media (--tablet) {
  .container {
    @apply flex-row;
  }

  .container .textContainer {
    margin-left: -12px;
  }

  .imageRightLarge {
    @apply flex-row-reverse;
  }

  .imageRightLarge .textContainer {
    margin-left: 0;
    margin-right: -12px;
  }
}
