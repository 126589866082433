.HeaderAnnouncement {
  @apply relative bg-brandYellow25;
}

.HeaderAnnouncement__Button {
  @apply flex rounded-full absolute p-1 top-1/2 right-2 -translate-y-2/4;
}

.arrow {
  @apply inline-flex items-center font-bold hover:bg-brandGrey;
}

.arrow svg {
  @apply inline ml-1;
}
