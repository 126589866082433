.container {
  @apply relative h-64 mb-4;
}

.link {
  @apply relative flex items-center justify-between h-full w-full;
}

.content {
  @apply flex flex-col mr-8 items-start relative;
}

.title {
  @apply mb-2 relative;
}

.image {
  @apply h-full object-contain;
  width: 40%;
}

.imageLoading .image {
  @apply opacity-0;
}

/* .imageLarge {
  @apply hidden;
} */

@media (--tablet) {
  .container {
    @apply h-72;
  }

  .link {
    @apply justify-center;
  }

  .title {
    font-size: 50px;
    line-height: 60px;
  }
}

/** Image alignment styles **/

/* Right */
.imageRight {
  @apply flex-row-reverse;
}

.imageRight .content {
  @apply ml-8 mr-0;
}

@media (--tablet) {
  .imageRightLarge {
    @apply flex-row-reverse;
  }

  .imageRightLarge .content {
    @apply ml-8 mr-0;
  }
}

/* Center */
.imageCenter {
  @apply justify-center;
}

.imageCenter .image {
  @apply absolute h-full w-full top-0 left-0 object-cover;
}

.imageCenter .title {
  @apply text-center;
}

.imageCenter .content {
  @apply items-center;
}

@media (--tablet) {
  .imageCenterLarge {
    @apply justify-center;
  }

  .imageCenterLarge .image {
    @apply absolute h-full w-full top-0 left-0 object-cover;
  }

  .imageCenterLarge .title {
    @apply text-center;
  }

  .imageCenterLarge .content {
    @apply items-center mr-0;
  }
}

/** Text alignment styles **/

/* Right */
.textRight .content {
  @apply items-end;
}

.textRight .title {
  @apply text-right;
}

@media (--tablet) {
  .textRightLarge .content {
    @apply items-end;
  }

  .textRightLarge .title {
    @apply text-right;
  }
}

/* Center */
.textCenter .content {
  @apply items-center;
}

.textCenter .title {
  @apply text-center;
}

@media (--tablet) {
  .textCenterLarge .content {
    @apply items-center;
  }

  .textCenterLarge .title {
    @apply text-center;
  }
}
