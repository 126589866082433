.content h2 {
  @apply font-display text-sm font-extrabold mb-2;
}

.title {
  @apply text-xl;
  font-size: 30px;
  line-height: 40px;
}

.content {
  @apply font-body text-sm;
}

.content p {
  @apply mb-8;
}

.content a {
  cursor: pointer;
  text-decoration: underline;
}

.error {
  @apply text-digitalRed mt-3;
}

@media (--tablet) {
  .content h2,
  .content p {
    @apply text-base;
  }

  .title {
    @apply text-xl;
    font-size: 40px;
    line-height: 50px;
  }
}
