.tooltip {
  @apply absolute bg-gray-100 text-black top-full border border-gray-300 p-1 shadow-md opacity-0 pointer-events-none normal-case;
  transition: 0.2s ease;
  top: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
  max-width: 80vw;
  font-weight: 100;
  width: 230px;
  border-radius: 8px;
  padding: 8px 12px;
  z-index: 3;
}

.tooltip.open {
  opacity: 1;
}

.tooltipArrow {
  @apply absolute top-0 left-1/2;
  transform-origin: center center;
  transform: translate(-50%, -50%) rotateZ(-45deg);
  border-top: 4px solid black;
  border-right: 4px solid black;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
}