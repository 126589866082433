.container {
  @apply relative;
}

.title {
  @apply mb-4;
}

@media (--tablet) {
  .fullWidth .items {
    @apply flex gap-10;
  }

  .fullWidth .item {
    @apply flex-row;
    margin-left: -12px;
    margin-right: 0;
  }
}
