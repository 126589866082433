.container {
  @apply relative flex flex-col;
}

.bordered {
  @apply border;
}

.imageContainer {
  @apply relative h-64 flex-shrink-0;
}

.image {
  @apply w-full h-full object-cover absolute;
}

.textContainer {
  @apply pt-4 pb-7 px-5 relative flex flex-col items-start justify-center;
}

.title {
  @apply mb-2;
}

.subtitle {
  @apply mb-5;
}

.link {
  @apply absolute h-full w-full top-0 left-0;
}

@media (--tablet) {
  .container:not(.grid) {
    @apply flex-row h-72;
  }

  .container:not(.grid) .imageContainer {
    @apply h-full w-1/2;
  }

  .container:not(.grid) .image {
    @apply object-contain;
  }

  .container:not(.grid) .textContainer {
    @apply m-7;
  }

  .container:not(.grid) .title {
    @apply text-2xl;
  }
}
