.container {
  @apply h-full flex flex-col items-center overflow-auto;
}

.list {
  @apply px-2  flex-1 pt-2 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-3 w-full;
}

.soldOut {
  @apply absolute top-0 left-0 h-full w-full flex justify-center items-center bg-white25;
}

.button {
  @apply h-12 px-8 py-4 border border-black mt-8 mb-10;
}
