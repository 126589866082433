/* Body normal */
@font-face {
  font-family: 'Faktum';
  src: url('../public/fonts/faktum-regular-webfont.woff2') format('woff2'),
    url('../public/fonts/faktum-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Body bold */

@font-face {
  font-family: 'Faktum';
  src: url('../public/fonts/faktum-bold-webfont.woff2') format('woff2'),
    url('../public/fonts/faktum-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Display normal */
@font-face {
  font-family: 'Bossa';
  src: url('../public/fonts/Bossa-Black.woff2') format('woff2'), url('../public/fonts/Bossa-Black.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Button */
@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Bold.woff2') format('woff2'), url('../public/fonts/Inter-Bold.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
