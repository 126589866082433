.container {
  @apply flex flex-wrap items-center justify-between pt-4;
}

.searchContainer {
  @apply mb-3 w-full mt-4 md:order-2;
}

.searchContainer > div {
  @apply flex-1;
}

.input {
  @apply block w-full h-full text-black75 border border-black outline-0 px-3;
}

.input input {
  height: 100%;
}

.btnWrapper {
  @apply text-white w-12 bg-brandYellow flex items-center flex-shrink-0 justify-center border border-l-0 border-black h-full;
}

.btn {
  @apply p-0 m-0 flex justify-center items-center;
}

.form {
  @apply relative flex flex-row-reverse h-12 border-none rounded-none;
}

.reset {
  @apply absolute right-16;
  top: 50%;
  transform: translateY(-50%);
}

/* Responsive styles */

.enableResponsive .label {
  @apply md:order-1 md:text-2xl lg:text-3xl;
}

.enableResponsive .toggle {
  @apply md:order-3 md:flex md:flex-1 md:justify-end;
}

.enableResponsive .searchContainer {
  @apply md:flex md:w-80 md:ml-5;
}

@media (--tablet) {
  .container {
    @apply py-4;
  }

  .toggleLink {
    @apply text-base;
  }
}
