.container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 350px;
  @apply relative flex flex-col justify-center items-center;
}

.contentBg {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 75%;
  @apply w-full flex flex-col justify-center items-center;
}

.contentBg::after {
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.contentBgNoOverlay {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 75%;
  @apply w-full flex flex-col justify-center items-center;
}

.content {
  @apply relative z-10 flex flex-col justify-center items-center p-6;
}

.logo {
  @apply w-64;
}

.text {
  @apply text-white text-sm text-center mt-4 max-w-xl;
}

@media (--tablet) {
  .container {
    height: 470px;
  }

  .logo {
    width: 600px;
  }

  .text {
    @apply text-base;
  }
}
