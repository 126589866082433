.container {
  @apply flex items-center px-3;
}

.imageContainer {
  @apply w-24 flex-shrink-0;
}

.textContainer {
  @apply ml-3;
}

.button {
  @apply h-8 mt-1;
}
