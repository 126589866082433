.imageContainer {
  @apply w-full overflow-hidden border border-gray-300 relative aspect-square p-2 bg-white;
}

.designerImageContainer {
  @apply w-full overflow-hidden border border-2 border-black relative aspect-square p-2 bg-white;
}

.image {
  @apply h-full object-scale-down object-center lg:h-full lg:w-full;
}

.wishlist {
  @apply absolute bottom-2 right-2;
}

.title {
  @apply mt-1 truncate;
}

.description {
  @apply truncate text-black50;
}
