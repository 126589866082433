.container {
  @apply relative flex justify-center items-center p-1 border h-12;
}

.image {
  @apply w-auto object-contain;
}

.link {
  @apply absolute h-full w-full top-0 left-0 z-10;
}
