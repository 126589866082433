.Header {
  @apply bg-brandYellow w-full;
}

.HeaderHidden {
  @apply hidden;
}

.HeaderContainer {
  @apply flex justify-between items-center;
  height: 60px;
}

.SecondHeaderContainer {
  @apply flex flex-row justify-between items-center md:hidden;
  height: 30px;
}

.HeaderLink {
  @apply text-black flex items-center justify-center top-2 pb-4 cursor-pointer h-11 relative;
}

.MobileHeaderLink {
  @apply w-full h-full flex items-center justify-center text-gray-700;
}

.ActiveTab {
  @apply bg-brandGrey;
}

.HeaderLinkIcon {
  @apply h-full flex items-center justify-center px-2;
}

.OffersLinkIcon {
  @apply h-full flex items-center justify-center pr-1;
}

.HeaderCenter {
  @apply flex md:justify-center mx-2 md:mx-4 flex-1;
}

.HeaderRight {
  @apply flex space-x-4 md:space-x-12 md:w-1/3 justify-end;
}

.HeaderLeft {
  @apply flex space-x-4 md:space-x-6 md:w-1/3 justify-start;
}

.HeaderTooltipWrapper {
  @apply z-10 absolute mt-2 -right-2 opacity-80;
  top: 110%;
}

.HeaderTooltipWrapperApp {
  top: initial;
  position: fixed;
  bottom: 60px;
  right: 20px;
  text-align: center;
}

.HeaderTooltipWrapperApp .HeaderTooltip:before {
  @apply absolute top-full w-0 h-0;
  content: '';
  right: 12px;
  border-bottom: initial;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid black;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.HeaderTooltipWrapperWishlist {
  right: 23%;
}

.HeaderTooltip {
  @apply bg-black w-56 py-3 px-2 text-white;
}

.HeaderTooltip:before {
  @apply absolute bottom-full w-0 h-0;
  content: '';
  right: 12px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid black;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

@media (--tablet) {
  .HeaderLinkIcon {
    @apply pl-0;
  }

  .HeaderLink span {
    @apply text-base;
  }
}
